import React, { StrictMode, Suspense } from 'react';
import App from './App';
import { BrowserRouter } from "react-router-dom"
import { createRoot } from 'react-dom/client';

createRoot(document.getElementById("root")).render(
	<StrictMode>
		<BrowserRouter>
			<Suspense fallback={<div>Loading....</div>}>
				<App />
			</Suspense>
		</BrowserRouter>
	</StrictMode>
)
