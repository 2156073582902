import React from 'react';

/**
 * Component for the About page
 * @return { Promise React.Component }
 */
export function AboutPage() {
    return (<div className='about-page-container'>
        <p>About</p>
    </div>)
}