import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
/**
 * Component for the Home page
 * @return { Promise React.Component }
 */
export function HomePage() {
    const { t, i18n } = useTranslation();
    const image = useRef(null);

    //For Paralax effect
    useEffect(() => {
        const handleScroll = () => {
            if (image.current) {
                image.current.style.backgroundPositionY = `${(window.scrollY) * 0.5}px`
            }
        }
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className='home-page-container'>
            <Box sx={{ textAlign: 'center', position: 'relative', height: '100vh', overflow: 'hidden' }}>
                <Box
                    ref={image}
                    component="div"
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: `url('images/RSI-Perseus.jpg') center/cover no-repeat`,
                    }}
                />
                <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: 1, color: 'white'}}>
                    <Typography variant="h1" gutterBottom>Section Hades</Typography>
                    <Typography variant="body1" style={{ padding: '0 20px' }}>{t('HomePageCorpDescirption')}</Typography>
                </div>
            </Box>
            <Divider/>
            <Box sx={{py: '75px', px: {xs: '25px', md: '250px', xl: '400px'}, backgroundColor: "#1c1c1c", color: '#e4e4e4'}}>
                <Typography variant="h4" gutterBottom sx={{color: '#e06666'}}>{t('BusinessAreasTitle')}</Typography>
                <Typography variant="body1" sx={{textAlign: 'justify'}}>{t('BusinessAreasDescription')}</Typography>
                <Box sx={{display: "grid", gridTemplateColumns: {sm:'repeat(1, 1fr)', xl:'repeat(2, 1fr)'}, gap: '20px', mt: '42px'}}>
                    <Box sx={{display: "flex"}}>
                        <Box
                        component="img"
                        sx={{
                            height: 'auto',
                            width: 200,
                            objectFit: 'cover',
                            pr: '10px',
                        }}
                        alt="Hull-C"
                        src="images/Hull-C.jpg"
                        />
                        <Box sx={{flex: 1}}>
                            <Typography variant="h6" gutterBottom sx={{color: '#e06666'}}>{t('ProtectionGoodsPersonsTitle')}</Typography>
                            <Typography variant="body2" sx={{textAlign: "justify"}}>{t('ProtectionGoodsPersonsDesciption')}</Typography>
                        </Box>
                    </Box>
                    <Box sx={{display: "flex"}}>
                        <Box
                        component="img"
                        sx={{
                            height: 'auto',
                            width: 200,
                            objectFit: 'cover',
                            pr: '10px',
                        }}
                        alt="Close Air Support"
                        src="images/Close-Air-Support.jpeg"
                        />
                        <Box>
                            <Typography variant="h6" gutterBottom sx={{color: '#e06666'}}>{t('FightingThreatsTitle')}</Typography>
                            <Typography variant="body2" sx={{textAlign: "justify"}}>{t('FightingThreatsDescription')}</Typography>
                        </Box>
                    </Box>
                    <Box sx={{display: "flex"}}>
                        <Box
                        component="img"
                        sx={{
                            height: 'auto',
                            width: 200,
                            objectFit: 'cover',
                            pr: '10px',
                        }}
                        alt="Tonk-Arrival"
                        src="images/Tonk-Arrival.jpg"
                        />
                        <Box>
                            <Typography variant="h6" gutterBottom sx={{color: '#e06666'}}>{t('FireSupportAndLogisticsTitle')}</Typography>
                            <Typography variant="body2" sx={{textAlign: "justify"}}>{t('FireSupportAndLogisticsDescription')}</Typography>
                        </Box>
                    </Box>
                    <Box sx={{display: "flex"}}>
                        <Box
                        component="img"
                        sx={{
                            height: 'auto',
                            width: 200,
                            objectFit: 'cover',
                            pr: '10px',
                        }}
                        alt="Bridge"
                        src="images/Consulting.jpg"
                        />
                        <Box>
                            <Typography variant="h6" gutterBottom sx={{color: '#e06666'}}>{t('ConsultingTitle')}</Typography>
                            <Typography variant="body2" sx={{textAlign: "justify"}}>{t('ConsultingDescription')}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Divider/>
            <Box sx={{py: '75px', px: {xs: '25px', md: '250px', xl: '400px'}, backgroundColor: "#434343", color: '#e4e4e4'}}>
                <Typography variant="h4" gutterBottom sx={{color: '#e06666', textAlign: 'center'}}>{t('WishContactTitle')}</Typography>
                <Typography variant="body1" sx={{textAlign: 'center'}}>{t('WishContactDescriptionPart1')}<a href='/customer'>{t('WishContactDescriptionLink1')}</a>{t('WishContactDescriptionPart2')}</Typography>
                <Typography variant="body1" sx={{textAlign: 'center'}}>{t('WishContactDescriptionPart3')}<a href="https://discord.com/invite/HZ4r5fPVF3">{t('WishContactDescriptionLink2')}</a>{t('WishContactDescriptionPart4')}</Typography>
            </Box>
            <Divider/>
        </div>
    );
}