import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import LanguageSelector from './language_selector';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { Paper } from '@mui/material';

const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  return (
    <AppBar position="fixed" sx={{background: "#000000F0"}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* For small screen */}
      <Box
        component="img"
        sx={{
          display: { xs: 'none', md: 'flex'},
          height: 30,
          width: 30,
        }}
        alt="The house from the offer."
        src="images/SH_LOGO.png"
      />
      <Typography
        variant="h6"
        noWrap
        component="a"
        sx={{
          mr: 2,
          display: { xs: 'none', md: 'flex' },
          fontFamily: 'monospace',
          fontWeight: 700,
          letterSpacing: '.3rem',
          color: 'inherit',
          textDecoration: 'none',
        }}
      >
        Section Hades
      </Typography>
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }}}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: 'block', md: 'none' },
          }}
        >
          <MenuItem key="Home" onClick={handleCloseNavMenu}>
            <Button disableRipple variant="text" onClick={() => navigate("/home")}>{t('Home')}</Button>
          </MenuItem>
          <MenuItem key="CustomerArea" onClick={handleCloseNavMenu}>
            <Button disableRipple variant="text" onClick={() => navigate("/customer")}>{t('CustomerArea')}</Button>
          </MenuItem>
          <MenuItem key="OurTeam" onClick={handleCloseNavMenu}>
            <Button disableRipple variant="text" onClick={() => navigate("/our-team")}>{t('OurTeam')}</Button>
          </MenuItem>
          <MenuItem key="Enroll" onClick={handleCloseNavMenu}>
            <Button disableRipple variant="text" onClick={() => navigate("/enroll")}>{t('Enroll')}</Button>
          </MenuItem>
          <MenuItem key="About" onClick={handleCloseNavMenu}>
            <Button disableRipple variant="text" onClick={() => navigate("/about")}>{t('About')}</Button>
          </MenuItem>
          <MenuItem key="LanguageSelector">
              <LanguageSelector />
          </MenuItem>
        </Menu>
      </Box>

          {/* For Big screen */}
          <Box
            component="img"
            sx={{
              display: { xs: 'flex', md: 'none'},
              height: 30,
              width: 30,
            }}
            alt="Section Hades LOGO"
            src="images/SH_LOGO.png"
          />
          <Typography
            variant="h5"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Section Hades
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Button key="Home" sx={{ my: 2, color: 'white', display: 'block' }} variant="text" onClick={() => navigate("/home")}>{t('Home')}</Button>
            <Button key="CustomerArea" sx={{ my: 2, color: 'white', display: 'block' }} variant="text" onClick={() => navigate("/customer")}>{t('CustomerArea')}</Button>
            <Button key="OurTeam" sx={{ my: 2, color: 'white', display: 'block' }} variant="text" onClick={() => navigate("/our-team")}>{t('OurTeam')}</Button>
            <Button key="Enroll" sx={{ my: 2, color: 'white', display: 'block' }} variant="text" onClick={() => navigate("/enroll")}>{t('Enroll')}</Button>
            <LanguageSelector/>
          </Box>
          

          {/* //For a futur account part
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
