import './css/app.css';
import { AppRoutes } from './app_routes';
import ResponsiveAppBar from './components/responsive_app_bar';
import Footer from './components/footer';

/**
 * Component Application
 * @return { Promise React.Component }
 */
function App() {
  	return (
    	<div className="App">
			<ResponsiveAppBar/>
      		<AppRoutes/>
			<Footer/>
    	</div>
  	);
}

export default App;
