import React from 'react';
import Box from '@mui/material/Box';
import { Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

/**
 * Component for the Error 404 page
 * @return { Promise React.Component }
 */
export function NotFoundPage() {
    const { t, i18n } = useTranslation();
    return (<div className='not-found-page-container'>
        <Box sx={{py: '75px', px: {xs: '25px', md: '250px', xl: '400px'}, height: '100vh',  backgroundColor: "#1c1c1c", color: '#e4e4e4'}}>
            <Typography variant='h3' className='error'>ERROR</Typography>
            <Typography variant="h1" title="404" className='glitched' sx={{fontSize: 200, mt: '15%'}}>404</Typography>

            <Typography variant="body1" sx={{mt: '15%'}}>Oh it seems that you went a bit to far.</Typography>
            <Typography variant="body1">Here is a way to go back onto known area <a href='/home'>...</a></Typography>
        </Box>
    </div>)
}