import React from 'react';
import Box from '@mui/material/Box';
import { Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

/**
 * Component for the Error 404 page
 * @return { Promise React.Component }
 */
export function OurTeamPage() {
    const { t, i18n } = useTranslation();
    return (<div className='our-team-page-container'>
        <Box sx={{py: '75px', px: {xs: '25px', md: '250px', xl: '400px'}, textAlign: 'center',  backgroundColor: "#1c1c1c", color: '#e4e4e4'}}>
            <Typography variant="h2" gutterBottom sx={{color: '#e06666'}}>{t('WhoAreWe')}</Typography>
        </Box>
        <Divider/>
        <Box sx={{py: '75px', px: {xs: '25px', md: '250px', xl: '300px'}, display: 'flex', flexDirection: 'column', alignItems: 'center',  backgroundColor: "#434343", color: '#e4e4e4'}}>
            <Box sx={{display: 'flex', flexDirection:{xs: 'column', md: 'column', xl: 'row'}, alignItems: 'center', justifyContent: 'space-evenly'}}>
                <Box sx={{display: 'flex', pb: '42px', flexDirection: 'column'}}>
                    <Box
                    component="img"
                    sx={{
                        height: 'auto',
                        width: 350,
                        objectFit: 'cover',
                        pr: '10px',
                    }}
                    alt="Aleksander Falkiewicz"
                    src="images/Falkiewicz.png"
                    />
                    <Box sx={{width: 350}}>
                        <Typography variant="h5" gutterBottom sx={{color: '#e06666'}}>Aleksander Falkiewicz</Typography>
                        <Typography variant="body1" sx={{textAlign: "justify"}}>{t('FalkiewiczDescription')}</Typography>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', pb: '42px', flexDirection: 'column'}}>
                    <Box
                    component="img"
                    sx={{
                        height: 'auto',
                        width: 350,
                        objectFit: 'cover',
                        pr: '10px',
                    }}
                    alt="Karl Djapamal"
                    src="https://c.tenor.com/HnKFNr-o3q8AAAAC/tenor.gif"
                    />
                    <Box sx={{width: 350}}>
                        <Typography variant="h5" gutterBottom sx={{color: '#e06666'}}>Karl Djapamal</Typography>
                        <Typography variant="body1" sx={{textAlign: "justify"}}>{t('DjapamalDescription')}</Typography>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', pb: '42px', flexDirection: 'column'}}>
                    <Box
                    component="img"
                    sx={{
                        height: 'auto',
                        width: 350,
                        objectFit: 'cover',
                        pr: '10px',
                    }}
                    alt="Rick Hobbs"
                    src="images/Hobbs.png"
                    />
                    <Box sx={{width: 350}}>
                        <Typography variant="h5" gutterBottom sx={{color: '#e06666'}}>Rick Hobbs</Typography>
                        <Typography variant="body1" sx={{textAlign: "justify"}}>{t('HobbsDescription')}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{textAlign: 'center'}}>
                <Box
                    component="img"
                    sx={{
                        width: '80%',
                        objectFit: 'cover',
                        pr: '10px',
                    }}
                    alt="Falkiewicz Team"
                    src="images/FalkiewiczTeam.png"
                    />
                    <Typography variant="body2">{t('TeamImageDescription1')}</Typography>
            </Box>
        </Box>
        <Divider/>
    </div>)
}