import React, {useState} from "react";
import i18n from '../i18n/i18n';
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";

const LanguageSelector = () => {
    
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language); // i18n.language contains the language assigned to lng in i18n.js file.

    const chooseLanguage = (e) => {
        e.preventDefault();
        i18n.changeLanguage(e.target.value);   // i18n.changeLanguage() is used to change the language assigned to lng in i18n.js file.
        setSelectedLanguage(e.target.value);
    }

    return (
        // <select defaultValue={selectedLanguage} onChange={chooseLanguage}>  
        //     <option value="fr">French</option>
        //     <option value="en">English</option>
        // </select>

        <Select
            value={selectedLanguage}
            onChange={chooseLanguage}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            sx={{color: "#ffffff"}}c
        >
            <MenuItem value="fr">🇫🇷 Français</MenuItem>
            <MenuItem value="en">🇬🇧 English</MenuItem>
        </Select>
        );
};

export default LanguageSelector;