import React from 'react';
import Box from '@mui/material/Box';
import { Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

/**
 * Component for the Error 404 page
 * @return { Promise React.Component }
 */
export function CustomerAreaPage() {
    const { t, i18n } = useTranslation();
    return (<div className='customer-area-page-container'>
        <Box  sx={{py: '75px', px: {xs: '25px', md: '250px', xl: '400px'}, textAlign: 'center',  backgroundColor: "#1c1c1c", color: '#e4e4e4'}}>
            <Typography variant="h2" gutterBottom sx={{color: '#e06666'}}>{t('CustomerAreaTitle')}</Typography>
            <Typography variant="body1">{t('CustomerAreaDescription')}</Typography>
        </Box>
        <Divider/>
        <Box  sx={{py: '75px', px: {xs: '25px', md: '250px', xl: '400px'}, backgroundColor: "#434343", color: '#e4e4e4'}}>
            <Typography variant="h4" gutterBottom sx={{color: '#e06666'}}>{t('Step1Title')}</Typography>
            <Typography variant="body1">{t('Step1DescriptionPart1')}<a href='https://discord.gg/HZ4r5fPVF3'>{t('Step1DescriptionLink1')}</a>{t('Step1DescriptionPart2')}</Typography>
        </Box>
        <Divider/>
        <Box  sx={{py: '75px', px: {xs: '25px', md: '250px', xl: '400px'}, backgroundColor: "#1c1c1c", color: '#e4e4e4'}}>
            <Typography variant="h4" gutterBottom sx={{color: '#e06666'}}>{t('Step2Title')}</Typography>
            <Typography variant="body1">{t('Step2Description')}</Typography>
        </Box>
        <Divider/>
        <Box  sx={{py: '75px', px: {xs: '25px', md: '250px', xl: '400px'}, backgroundColor: "#434343", color: '#e4e4e4'}}>
            <Typography variant="h4" gutterBottom sx={{color: '#e06666'}}>{t('Step3Title')}</Typography>
            <Typography variant="body1">{t('Step3Description')}</Typography>
        </Box>
        <Divider/>
        <Box  sx={{py: '75px', px: {xs: '25px', md: '250px', xl: '400px'}, backgroundColor: "#1c1c1c", color: '#e4e4e4'}}>
            <Typography variant="h4" gutterBottom sx={{color: '#e06666'}}>{t('Step4Title')}</Typography>
            <Typography variant="body1">{t('Step4Description')}</Typography>
        </Box>
        <Divider/>
    </div>)
}