import { useTranslation } from 'react-i18next'
import React from 'react'
import Box from '@mui/material/Box';
import { Divider } from '@mui/material';
import Typography from '@mui/material/Typography';

const Footer = () => {
    const { t } = useTranslation();
    return (
        <Box sx={{display: 'flex', justifyContent: 'center', py: '10px', backgroundColor: "#434343", color: '#e4e4e4'}}>
        <a href="https://discord.com/invite/HZ4r5fPVF3">
            <Box component="img" src="images/Discord-Logo.svg" sx={{height: 100, px: '30px'}}></Box>
        </a>
        <Divider orientation="vertical" flexItem/>
        <a href="https://robertsspaceindustries.com/orgs/SILHUN">
            <Box component="img" src="images/RSI-Logo.svg" sx={{height: 100}}></Box>
        </a>
    </Box>
    )
}

export default Footer


