import React from 'react';
import Box from '@mui/material/Box';
import { Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';

/**
 * Component for the Error 404 page
 * @return { Promise React.Component }
 */
export function EnrollPage() {
    const { t, i18n } = useTranslation();
    return (<div className='enroll-page-container'>
        <Box sx={{py: '75px', px: {xs: '25px', md: '250px', xl: '400px'}, backgroundColor: "#1c1c1c", color: '#e4e4e4', textAlign: 'center'}}>
            <Typography variant="h2" gutterBottom sx={{color: '#e06666'}}>{t('EnrollmentPageTitle')}</Typography>
            <Typography variant="body1">{t('EnrollmentPageDescription')}</Typography>
        </Box>
        <Divider/>
        <Box  sx={{py: '75px', px: {xs: '25px', md: '250px', xl: '400px'}, backgroundColor: "#434343", color: '#e4e4e4'}}>
            <Typography variant="h4" gutterBottom sx={{color: '#e06666'}}>{t('EnrollmentProtocolTitle')}</Typography>
            <Typography variant="body1">{t('EnrollmentProtocolDescription')}</Typography>
            <Button>{t('EnrollButton')}</Button>
        </Box>
        <Divider/>
    </div>)
}