import { Navigate, Route, Routes } from "react-router-dom";
import { HomePage, AboutPage, NotFoundPage, CustomerAreaPage, OurTeamPage, EnrollPage } from "./pages";

/**
 * Component en charge du routage dans l'application
 * @return { Promise React.Component }
 */
export function AppRoutes() {
	return (
        <Routes>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/customer" element={<CustomerAreaPage />} />
            <Route path="/our-team" element={<OurTeamPage />} />
            <Route path="/enroll" element={<EnrollPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/404" element={<NotFoundPage />} />
            <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
	)
}